<template>
  <div class="home">
    <div class="product-box">
      <div class="product-tools">
        <div
          v-for="(item, index) in productList"
          :class="productActive == index ? 'active' : ''"
          :key="index"
          @click="changeProduct(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <el-carousel
        class="product-list"
        ref="productList"
        :initial-index="productActive"
        :autoplay="true"
        :loop="true"
        @change="changeActive"
        @mouseenter.native="delHandleMouseEnter"
        indicator-position="none"
        height="1088px"
      >
        <el-carousel-item>
          <div class="product-01">
            <transition name="fade">
              <div class="text-box" v-if="productActive == 0">
                <div class="title">慧视 皓夜系列</div>
                <div class="text">暗夜守护者</div>
                <div class="btn-box">
                  <div class="btn" @click="goGoodsList">
                    <span>皓夜系列</span>
                    <img src="../../assets/right-btn.png" alt="" />
                  </div>
                  <!--                            <div class="btn">-->
                  <!--                              <span>AI  ISP</span>-->
                  <!--                              <img src="../../assets/right-btn.png" alt="">-->
                  <!--                            </div>-->
                </div>
              </div>
            </transition>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="product-02">
            <transition name="fade">
              <div class="text-box" v-if="productActive == 1">
                <div class="title">3D数字孪生 数智城市</div>
                <div class="text">AI AR 多维度</div>
                <!--                    <div class="btn-box">-->
                <!--                      <div class="btn">-->
                <!--                        <span>了解更多</span>-->
                <!--                        <img src="../../assets/right-btn.png" alt="">-->
                <!--                      </div>-->
                <!--                    </div>-->
              </div>
            </transition>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="product-03">
            <transition name="fade">
              <div class="text-box" v-if="productActive == 2">
                <div class="text" style="margin-bottom: 40px">行业智慧赋能</div>
                <div class="title">多 维 感 知 赋 能 智 慧 警 务</div>
                <div class="title">防 控 升 级 推 进 社 会 治 理</div>
                <div class="text">向科技要警力 向数据要警力</div>
              </div>
            </transition>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="role-box">
      <div class="role-item">
        <div>
          <div class="role-icon">
            <img src="../../assets/role-icon01.png" alt="" />
          </div>
          <div class="title">多维感知</div>
          <div class="text-box">
            <div>触感城市脉搏</div>
            <div>摄控目标动态</div>
          </div>
        </div>
      </div>
      <div class="role-item">
        <div>
          <div class="role-icon">
            <img src="../../assets/role-icon02.png" alt="" />
          </div>
          <div class="title">主动预警</div>
          <div class="text-box">
            <div>事后转向事前</div>
            <div>被动转向主动</div>
          </div>
        </div>
      </div>
      <div class="role-item">
        <div>
          <div class="role-icon">
            <img src="../../assets/role-icon03.png" alt="" />
          </div>
          <div class="title">智慧赋能</div>
          <div class="text-box">
            <div>行业提质增效</div>
            <div>城市智慧升级</div>
          </div>
        </div>
      </div>
      <div class="role-item">
        <div>
          <div class="role-icon">
            <img src="../../assets/role-icon04.png" alt="" />
          </div>
          <div class="title">服务百业</div>
          <div class="text-box">
            <div>服务千行百业</div>
            <div>赋能实战场景</div>
          </div>
        </div>
      </div>
    </div>
    <div class="plan-box">
      <div class="title">行业方案</div>
      <div class="text">
        汇集科技智慧,赋能千行百业，助力智慧城市提升数字化治理水平
      </div>
      <div class="plan-content">
        <el-carousel
          :autoplay="false"
          arrow="always"
          type="card"
          indicator-position="none"
          height="500px"
        >
          <el-carousel-item v-for="(item, index) in planList" :key="index">
            <div
              class="plan-item"
              :style="{ backgroundImage: 'url(' + item.homePicture + ')' }"
              @click="goPlanDetail(item.id)"
            >
              <div>
                <div class="title">{{ item.homeTitle }}</div>
                <div class="info">{{ item.homeInfo }}</div>
                <div class="more">了解更多></div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="technology-box">
      <div class="title">技术产品</div>
      <div class="text">
        以算法为基础，以应用为驱动，不断进行产品创新，一起见证领先科技力量
      </div>
      <div class="technology-content">
        <div
          style="cursor: pointer"
          :class="technologyIndex == index ? 'test' : ''"
          :style="{ backgroundImage: 'url(' + item.homePicture + ')' }"
          @click="goGoodsDetai(item)"
          @mouseover="technologyIndex = index"
          v-for="(item, index) in goodsList"
          :key="index"
        >
          <div
            class="title"
            style="
              font-size: 20px;
              margin-left: 60px;
              margin-top: 154px;
              white-space: nowrap;
            "
          >
            {{ item.productName }}
          </div>
          <div class="content">
            <div class="text" style="font-size: 14px">
              {{ item.productTitle }}
            </div>
            <div style="font-size: 14px; padding-top: 20px">查看详情 ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="case-box">
      <div class="title">项目案例</div>
      <div class="text">
        在各行各业，安软智慧视觉正通过创新的产品技术去改变人们的工作和生活，使很多地方都面貌一新，如此不同
      </div>
      <div class="case-tools">
        <div
          class="case-item"
          v-for="(item, index) in projectList"
          :key="index"
          :class="caseActive == index ? 'active' : ''"
          @click="changeCase(index)"
        >
          <div>{{ item.caseTypeName }}</div>
        </div>
      </div>
      <el-carousel
        class="case-list"
        ref="caseList"
        :autoplay="true"
        indicator-position="none"
        @change="changeCaseList"
        height="680px"
      >
        <el-carousel-item v-for="(item, index) in projectList" :key="index">
          <div
            class="case-img"
            :style="{ backgroundImage: 'url(' + item.homePicture + ')' }"
            @click="goProjectDetail(item.id)"
          >
            <div>
              <div class="text">铸行业利器 促社会和谐</div>
              <div class="info">{{ item.homeInfo }}</div>
              <div class="title">{{ item.homeTitle }}</div>
              <div class="more">了解更多 ></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      productList: [
        { name: "AI全彩夜视" },
        { name: "3D实景地图" },
        { name: "AI视图防控" },
      ],
      caseList: [
        { name: "公安" },
        { name: "治理" },
        { name: "园区" },
        { name: "校园" },
      ],
      productActive: 0,
      technologyList: [
        {
          name: "黑目 AIPC-HYT4",
          text: "夜视全彩 拍摄出色",
        },
        {
          name: "黑目 AIPC-HYT4",
          text: "夜视全彩 拍摄出色",
        },
        {
          name: "黑目 AIPC-HYT4",
          text: "夜视全彩 拍摄出色",
        },
        {
          name: "黑目 AIPC-HYT4",
          text: "夜视全彩 拍摄出色",
        },
      ],
      technologyIndex: 0,
      caseActive: 0,
      planList: [],
      projectList: [],
      goodsList: [],
    };
  },
  created() {
    this.getPlanList();
    this.getProjectList();
    this.getGoodsList();
  },
  methods: {
    delHandleMouseEnter() {
      this.$refs.productList.handleMouseEnter = () => {};
    },
    changeActive(val) {
      this.productActive = val;
    },
    changeProduct(index) {
      this.productActive = index;
      this.$refs.productList.setActiveItem(index);
    },
    changeCase(index) {
      this.caseActive = index;
      this.$refs.caseList.setActiveItem(index);
    },
    changeCaseList(index) {
      this.caseActive = index;
    },
    goGoodsList() {
      this.$router.push({
        name: "night",
        query: {
          id: "2",
        },
      });
    },
    getPlanList() {
      this.$get("/website/solution/list?isShowHome=1")
        .then((res) => {
          this.planList = res.rows;
        })
        .catch((err) => {
          this.msgError(err.msg);
        });
    },
    goPlanDetail(id) {
      this.$router.push({
        name: "planDetail",
        query: {
          id: id,
        },
      });
    },
    getProjectList() {
      this.$get("/website/case/list?isShowHome=1")
        .then((res) => {
          this.projectList = res.rows;
        })
        .catch((err) => {
          this.msgError(err.msg);
        });
    },
    goProjectDetail(id) {
      this.$router.push({
        name: "projectDetail",
        query: {
          id: id,
        },
      });
    },
    getGoodsList() {
      this.$get("/website/product/list?isShowHome=1")
        .then((res) => {
          this.goodsList = res.rows;
        })
        .catch((err) => {});
    },
    goGoodsDetai(item) {
      if (item.styleType == 1) {
        this.$router.push({
          name: "night",
          query: {
            id: item.id,
          },
        });
      }
      if (item.styleType == 2) {
        this.$router.push({
          name: "eyes",
          query: {
            id: item.id,
          },
        });
      }
      if (item.styleType == 3) {
        this.$router.push({
          name: "engine",
          query: {
            id: item.id,
          },
        });
      }
      if (item.styleType == 4) {
        this.$router.push({
          name: "parsing",
          query: {
            id: item.id,
          },
        });
      }
      if (item.styleType == 5) {
        this.$router.push({
          name: "algorithm",
          query: {
            id: item.id,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.el-icon-arrow-left,
.el-icon-arrow-right {
  font-size: 24px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease;
}
.fade-leave-to {
  opacity: 0;
  height: 500px;
  transform: translateX(60px);
}
.fade-enter {
  opacity: 0;
  height: 500px;
  transform: translateY(-60px);
}
</style>
<style scoped lang="scss">
.home {
  .technology-box {
    margin-top: 130px;
    > div.title {
      font-weight: 400;
      font-style: normal;
      font-size: 54px;
      color: #333;
      text-align: center;
    }
    > div.text {
      margin: 30px 0;
      font-weight: 400;
      font-style: normal;
      color: #797979;
      font-size: 24px;
      text-align: center;
    }
    .technology-content {
      display: flex;
      min-width: 1200px;
      > div {
        width: calc((100% - 1000px) / 3);
        height: 823px;
        background-repeat: no-repeat;
        background-size: cover;
        transition: width 1s ease;
        .content {
          margin-top: 86px;
          margin-left: 60px;
          opacity: 0;
          .title {
            font-size: 16px;
            font-weight: 400;
          }
          .text {
            font-size: 24px;
            font-weight: 400;
          }
        }
      }
      .test {
        width: 1000px;
        transition: width 1s ease;
        .content {
          transition: all 1s ease;
          opacity: 1;
          transform: translateY(-80px);
        }
      }
    }
  }
  .case-box {
    position: relative;
    margin-top: 130px;
    .case-tools {
      position: absolute;
      top: 220px;
      right: 10%;
      z-index: 999;
      display: flex;
      .case-item {
        padding: 0 30px;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        cursor: pointer;
        border-right: 4px solid #fff;
      }
      .case-item:last-of-type {
        border-right: none;
      }
      .case-item.active {
        color: black;
      }
    }
    > div.title {
      font-weight: 400;
      font-style: normal;
      font-size: 54px;
      color: #333;
      text-align: center;
    }
    > div.text {
      margin: 30px 0;
      font-weight: 400;
      font-style: normal;
      color: #797979;
      font-size: 24px;
      text-align: center;
    }
    .case-img {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      > div {
        margin-left: 18%;
        color: #fff;
        .text {
          padding-top: 90px;
          font-size: 24px;
          font-weight: bold;
        }
        .info {
          margin-top: 100px;
          font-size: 24px;
          margin-bottom: 20px;
        }
        .title {
          font-size: 42px;
        }
        .more {
          font-size: 18px;
          margin-top: 120px;
          cursor: pointer;
        }
      }
    }
  }
  .product-box {
    position: relative;
    .title {
      font-size: 48px;
      font-weight: 400;
      color: #f2f2f2;
    }
    .product-tools {
      position: absolute;
      top: 660px;
      left: 10%;
      z-index: 999;
      color: #aeaeae;
      > div {
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        margin-bottom: 20px;
        padding-left: 10px;
        border-left: 3px solid transparent;
      }
      > div.active {
        color: #fff;
        border-left: 3px solid #fff;
      }
    }
    .product-01 {
      width: 100%;
      height: 100%;
      background-image: url("../../assets/product-01.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      .text-box {
        margin-top: 200px;
        text-align: center;
        .text {
          font-weight: 400;
          font-style: normal;
          font-size: 28px;
          color: #f2f2f2;
        }
        .btn-box {
          font-style: normal;
          color: #f2f2f2;
          font-size: 18px;
          display: flex;
          justify-content: center;
          margin-top: 40px;
          .btn {
            width: 140px;
            height: 36px;
            line-height: 36px;
            font-weight: 400;
            border: 1px solid rgba(228, 228, 228, 1);
            border-radius: 22px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
          }
          .btn:hover {
            background-color: rgba(255, 255, 255, 0.12);
          }
          img {
            vertical-align: middle;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    .product-02 {
      width: 100%;
      height: 100%;
      background-image: url("../../assets/product-02.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      .text-box {
        margin-top: 200px;
        text-align: center;
        .text {
          font-weight: 400;
          font-style: normal;
          font-size: 28px;
          color: #f2f2f2;
        }
        .btn-box {
          font-style: normal;
          color: #f2f2f2;
          font-size: 18px;
          margin-top: 40px;
          display: flex;
          justify-content: center;
          .btn {
            width: 140px;
            height: 36px;
            line-height: 36px;
            font-weight: 400;
            border: 1px solid rgba(228, 228, 228, 1);
            border-radius: 22px;
            cursor: pointer;
          }
          .btn:hover {
            background-color: rgba(255, 255, 255, 0.12);
          }
          img {
            vertical-align: middle;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    .product-03 {
      width: 100%;
      height: 100%;
      background-image: url("../../assets/product-03.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      .text-box {
        margin-top: 200px;
        text-align: center;
        .text {
          font-weight: 400;
          font-style: normal;
          font-size: 28px;
          color: #f2f2f2;
        }
      }
    }
  }
  .role-box {
    width: 100%;
    height: 1080px;
    background-image: url("../../assets/role-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    .role-item {
      height: 100%;
      width: calc(100% / 4);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .role-icon,
      .text-box {
        opacity: 0;
      }
      .text-box {
        color: #fff;
        font-size: 32px;
        margin-top: 20px;
      }
      .title {
        font-size: 48px;
        color: #f2f2f2;
        font-weight: 400;
      }
    }
    .role-item:hover {
      transition: all 1s;
      background: black;
      opacity: 0.8;
      .role-icon,
      .text-box {
        opacity: 1;
      }
    }
  }
  .plan-box {
    margin-top: 130px;
    text-align: center;
    .title {
      font-weight: 400;
      font-style: normal;
      font-size: 54px;
      color: #333;
    }
    .text {
      margin: 30px 0;
      font-weight: 400;
      font-style: normal;
      color: #797979;
      font-size: 24px;
    }
    .plan-content {
      .plan-item {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        display: flex;
        justify-content: center;
        color: #fff;
        text-align: center;
        > div {
          .title {
            margin-top: 120px;
            color: #fff;
            font-size: 36px;
            margin-bottom: 20px;
          }
          .info {
            font-size: 18px;
            margin-bottom: 50px;
          }
          .more {
          }
        }
      }
    }
  }
}
</style>
